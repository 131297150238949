<template language="html">
  <div class="gestao-permissoes">
    <crud
      :title="'Gestão de permissões'"
      buttonAddItemText="Nova permissão"
      :dialog.sync="dialog"
      :formTitle="formTitle"
      :callBackModalCancelar="fechar"
      :callBackModalSalvar="salvar"
      :callBackModalInsert="resetFields"
      :loadingButtonSave="loadingControl.loadingSalvar"
      :tamanhoModalCrud="500"
      searchLabelText="Pesquisar na lista"
      :searchTextField.sync="searchTextField"
      :dialogDelete.sync="dialogDelete"
    >
      <template slot="data-table">
        <v-data-table
          :loading="loadingControl.dataTable"
          :headers="computedHeaders"
          :items="listaPermissoes"
          :search="searchTextField"
          items-per-page-text="Linhas por página"
          no-results-text="Nenhum registro encontrado"
          no-data-text="Nenhum registro encontrado"
          mobile-breakpoint="0"
          class="elevation-1"
        >
          <template v-slot:[`item.acao`]="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="toggleModalLateral(item)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2"> mdi-eye </v-icon>
                    <span>Visualizar</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="editar(item)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2"> mdi-pencil </v-icon>
                    <span>Editar</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="remover(item, true)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2"> mdi-delete </v-icon>
                    <span>Apagar</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ item.created_at | dateTimeFormatBr() }}</span>
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            <span>{{ item.updated_at | dateTimeFormatBr() }}</span>
          </template>
        </v-data-table>
      </template>
      <template slot="contentModalCrud">
        <v-container fluid>
          <v-row wrap>
            <v-col cols="12" md="12">
              <v-text-field
                id="name"
                name="name"
                data-vv-validate-on="change|blur|input"
                data-vv-as="nome"
                data-vv-scope="permission"
                :error="errors.collect('permission.nome').length ? true : false"
                :hint="errors.first('permission.nome')"
                v-validate="{ required: true }"
                v-model="objetoEdicao.name"
                persistent-hint
                trim
                type="text"
                label="nome"
                placeholder="nome"
                outlined
                clearable
              />
            </v-col>
            <v-col cols="12" md="12">
              <v-select
                :items="locais"
                item-text="text"
                item-value="value"
                label="Guardas"
                id="guard_name"
                name="guard_name"
                data-vv-validate-on="change|blur|input"
                data-vv-as="guarda"
                data-vv-scope="permission"
                :error="
                  errors.collect('permission.guard_name').length ? true : false
                "
                :hint="errors.first('permission.guard_name')"
                v-validate="{ required: true }"
                v-model="objetoEdicao.guard_name"
                persistent-hint
                trim
                type="text"
                outlined
                clearable
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <!-- ------------- MODAL DE DELETE --------------------------------------------- -->
      <template slot="modalDelete/titleModal">Excluir o registro?</template>
      <template slot="modalDelete/contentModal">
        <p>
          Atenção! Você está prestes a realizar uma ação que não pode ser
          desfeita.
        </p>
        <p>Você realmente deseja excluir esse registro?</p>
      </template>
      <template slot="modalDelete/actionsModal">
        <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
        <v-btn
          :loading="loadingControl.loadingDelete"
          color="error"
          @click="remover()"
          >Excluir</v-btn
        >
        <v-spacer class="d-none d-md-block"></v-spacer>
      </template>
    </crud>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="image" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>
    <modalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="
        headersModal.filter((header) => {
          return headersModal.value != 'visualizar';
        })
      "
      :item="modalLateral.item"
    >
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}</span
        >
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <span
          >{{ item.updated_at | dateFormatBr() }}
          {{ item.updated_at | timeFormatBr() }}</span
        >
      </template>
    </modalLateral>
  </div>
</template>
<script>
import logo from "@/assets/images/logos/legiio.png";
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";

export default {
  name: "gestao-permissoes",
  inject: ["$validator"],
  components: { Crud, ModalLateral },
  metaInfo() {
    return {
      title: "Gestão de permissões",
    };
  },
  data() {
    return {
      image: logo,
      searchTextField: "",
      item: [],
      dialogDelete: false,
      dialog: false,
      loadingControl: {
        dataTable: false,
        loadingSalvar: false,
        loadingDelete: false,
      },
      headers: [
        { text: "Nome", value: "name", sortable: true },
        {
          text: "Local",
          value: "guard_name",
          sortable: false,
        },
        {
          text: "Cadastro",
          value: "created_at",
          sortable: true,
        },
        {
          text: "Atualização",
          value: "updated_at",
          sortable: true,
        },
        { text: "Ações", value: "acao", sortable: false, align: "center" },
      ],
      headersModal: [
        {
          text: "Nome",
          value: "name",
        },
        {
          text: "Local",
          value: "guard_name",
        },
        {
          text: "Data de criação",
          value: "created_at",
        },
        {
          text: "Última alteração",
          value: "updated_at",
        },
      ],
      locais: [
        { text: "Web", value: "web" },
        { text: "API", value: "api" },
      ],
      listaDataTable: [],
      objetoSalvar: [],
      lista: [],
      nome: "",
      guard_name: "",
      modalLateral: {
        show: false,
        item: [],
        title: "",
      },
      buttonAction: false,
      objetoEdicao: {
        name: "",
        guard_name: "",
      },
      indexEdicao: false,
      posicaoEdicao: -1,
    };
  },
  methods: {
    toggleModalLateral(item) {
      this.modalLateral.title = this.modalLateral.show ? "" : item.nome;
      this.modalLateral.item = this.modalLateral.show ? [] : item;
      this.modalLateral.show = this.modalLateral.show ? false : true;
    },
    editar(item) {
      this.objetoEdicao.name = "";
      this.objetoEdicao.guard_name = "";
      this.indexEdicao = true;
      this.posicaoEdicao = this.listaPermissoes.indexOf(item);
      this.objetoEdicao = Object.assign({}, item);
      this.dialog = true;
    },
    resetFields() {},
    fechar() {
      this.dialog = false;
      this.objetoEdicao = {
        name: "",
        guard_name: "",
      };
    },
    salvar() {
      this.$validator.validate("permission.*").then((result) => {
        if (result) {
          this.loadingControl.dataTable = true;

          let url =
            this.indexEdicao === false
              ? "/permissao"
              : "/permissao/" + this.objetoEdicao.id;
          let method = this.indexEdicao === false ? "POST" : "PUT";
          let text =
            this.indexEdicao === false
              ? "Permissão cadastrado."
              : "Permissão atualizado.";
          let erro =
            this.indexEdicao === false
              ? "Erro ao salvar permissão."
              : "Erro ao atualizar permissão.";
          let objeto = this.objetoEdicao;
          objeto._method = method;
          objeto.url = url;
          this.$store
            .dispatch("configuracoes/salvarPermissao", { objeto })
            .then((response) => {
              this.indexEdicao === false
                ? this.listaPermissoes.push(response)
                : Object.assign(
                    this.listaPermissoes[this.posicaoEdicao],
                    objeto
                  );

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: text,
              });
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "error",
                timeout: 3000,
                text: erro,
              });
            })
            .finally(() => {
              this.loadingControl.dataTable = false;
              this.indexEdicao = false;
            });
        }

        this.fechar();
      });
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = item;
        this.dialogDelete = true;
      } else {
        this.loadingTable = true;
        this.loadingControl.loadingDelete = true;
        const indexItem = this.listaPermissoes.indexOf(this.item);

        this.$store
          .dispatch("configuracoes/removerPermissao", this.item.id)
          .then((response) => {
            if (response.data === true) {
              this.listaPermissoes.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Registro removido.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possivel remover o registro.",
            });
          })
          .finally(() => {
            this.loadingTable = false;
            this.dialogDelete = false;
            this.loadingControl.loadingDelete = false;
          });
      }
    },
  },
  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
    formTitle() {
      return this.indexEdicao === false
        ? "Cadatrar permissão"
        : "Editar permissão";
    },
    listaPermissoes: {
      get() {
        return this.$store.getters["configuracoes/getPermissoes"];
      },
      set(value) {
        this.$store.dispatch("configuracoes/setPermissoes", value);
      },
    },
    loading: {
      get() {
        return this.$store.getters["configuracoes/getLoadingPermissoes"];
      },
      set(value) {
        this.$store.dispatch("configuracoes/setLoadingPermissoes", value);
      },
    },
  },
  created() {
    this.$store.dispatch("configuracoes/buscarPermissoes");
  },
};
</script>
<style lang="scss"></style>
